/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Voucher, Subtitle } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="pb--20 pt--60" name={"wstęp"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s2 --center" anim={"2"} animS={"2"} style={{"maxWidth":1420}}>
              
              <Title className="title-box fs--72" content={"Gift Cards"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":800}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Voucher className="pb--40 pt--20" name={"4v9x9l8pw25"}>
        </Voucher>


        <Column className="pb--60 pt--60" name={"how-it-works"} style={{"backgroundColor":"var(--color-custom-1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center" content={"<span style=\"color: white;\">How it works</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s2" anim={"4"} animS={"2"}>
              
              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: white;\">\"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować.\"</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s2" anim={"4"} animS={"2"}>
              
              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: white;\">\"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować.\"</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s2" anim={"4"} animS={"2"}>
              
              <Text className="text-box" style={{"maxWidth":334}} content={"<span style=\"color: white;\">\"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować.\"</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"stopka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--2" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Gift Cards</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"+48 797 811 2X0<br>info@twoje-strony.com"}>
              </Text>

              <Text className="text-box" content={"REGON: 12345678<br>Utworzone przez<a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}